/**
 * @file download.jsx
 * @description Download page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Meta from "../components/Meta";
import Hero from "../components/hero";
import { config } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import downloadFile from "../util/downloadFile";

/**
 * @function DownloadPage
 * @description Download page.
 * @returns {JSX.Element} Download page.
 */

function DownloadPage() {
  config.autoAddCss = false;

  const [emailError, setEmailError] = useState(false);
  const [versionError, setVersionError] = useState(false);
  const [licenseError, setLicenseError] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    if (e.target.email.value === "") {
      setEmailError(true);
    } else {
      setEmailError(false);
      if (new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(e.target.email.value)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
    if (e.target.version.value === "") {
      setVersionError(true);
    } else {
      setVersionError(false);
    }
    if (!e.target.license.checked) {
      setLicenseError(true);
    } else {
      setLicenseError(false);
    }
    if (!e.target.privacy.checked) {
      setPrivacyError(true);
    } else {
      setPrivacyError(false);
    }
    if (emailError || versionError || licenseError || privacyError) {
      return;
    }
    if (
      e.target.email.value !== "" &&
      e.target.version.value !== "" &&
      e.target.license.checked
    ) {
      setEmailError(false);
      setVersionError(false);
      setLicenseError(false);
      setPrivacyError(false);
      downloadFile(e.target.email.value, e.target.version.value);
    }
  };

  return (
    <>
      <Meta
        title="Download"
        description="Download the latest version of Flatbrit."
      />
      <Hero
        title="Download"
        subtitle="Download the latest version of Flatbrit."
        color="is-primary"
      />
      <div className="section">
        <div className="container">
          <article className="message is-info">
            <div className="message-header">
              <p>Information</p>
            </div>
            <div className="message-body">
              <p>
                Please fill out the form below to download the latest version of
                the style. We don't send spam, we promise!
              </p>
            </div>
          </article>
          <form onSubmit={submitForm}>
            <div className="field">
              <label className="label" htmlFor="email">
                Email <span className="customOptionRequired">*</span>
              </label>
              <div
                className={`control has-icons-left ${
                  emailError ? "has-icons-right" : ""
                }`}
              >
                <input
                  className={`input ${emailError ? "is-danger" : ""}`}
                  id="email"
                  type="email"
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                {emailError && (
                  <span className="icon is-small is-right">
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                  </span>
                )}
              </div>
              {emailError && (
                <p className="help is-danger">This field is required</p>
              )}
            </div>
            <div className="field">
              <label className="label" htmlFor="version">
                Version <span className="customOptionRequired">*</span>
              </label>
              <div className="control">
                <div className={`select ${versionError ? "is-danger" : ""}`}>
                  <select defaultValue="5.5.0" id="version">
                    <option value="1.0.0">1.0.0</option>
                    <option value="2.0.0">2.0.0</option>
                    <option value="5.5.0">5.5.0</option>
                  </select>
                </div>
              </div>
              {versionError && (
                <p className="help is-danger">This field is required</p>
              )}
            </div>
            <hr />
            <div className="field">
              <input
                className={`is-checkradio ${
                  licenseError ? "has-background-color is-danger" : ""
                }`}
                type="checkbox"
                id="license"
              />
              <label className="checkbox" htmlFor="license">
                &nbsp;I agree to the{" "}
                <Link to="/license">license agreement</Link>.{" "}
                <span className="customOptionRequired">*</span>
              </label>
              {licenseError && (
                <p className="help is-danger">This field is required</p>
              )}
            </div>
            <div className="field">
              <input
                className={`is-checkradio ${
                  privacyError ? "has-background-color is-danger" : ""
                }`}
                type="checkbox"
                id="privacy"
              />
              <label className="checkbox" htmlFor="privacy">
                &nbsp;I have read and agree to the{" "}
                <Link to="/privacy-policy">privacy policy</Link>.{" "}
                <span className="customOptionRequired">*</span>
              </label>
              {privacyError && (
                <p className="help is-danger">This field is required</p>
              )}
            </div>
            <br />
            <div className="field is-grouped">
              <div className="control">
                <button className="button is-primary">Download now</button>
              </div>
              <div className="control">
                <Link to="/" className="button is-primary is-light">
                  Cancel
                </Link>
              </div>
            </div>
            <p>
              <span className="customOptionRequired">*</span> Required fields
            </p>
          </form>
        </div>
      </div>
    </>
  );
}

export default DownloadPage;
